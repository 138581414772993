.breadcrumbContainer {
  display: flex;
  margin-left: 20px;
}

.breadcrumb {
  margin: auto 0;

  a {
    color: #888;
    text-decoration: none;

    &:hover {
      color: #222;
    }
  }

  .badge {
    color: white;
    font-size: 14px;
    background-color: #777;
    border-radius: 5px;
    padding: 1px 4px 2px 4px;

    &:not(:last-child) {
      margin-right: 4px;
    }
  }

  &:not(:last-child) {
    &::after {
      content: '〉';
      color: #ddd;
      font-weight: 900;
      margin: 0 12px;
    }
  }

  &:last-child {
    a {
      color: #222;
      font-weight: 700;
    }
  }
}
