$invalid-color: #f44;

input,
select {
  width: 100%;
  font-size: 18px;
  border: none;
  border-bottom: 1px solid #ddd;
  margin: 8px 0;
  padding: 12px 16px;
  box-sizing: border-box;
  border-radius: 0;
  font-weight: 500;
  line-height: 140%;

  &.invalid {
    color: $invalid-color;
    border-bottom: 1px solid lighten($invalid-color, 10);

    &::placeholder {
      color: $invalid-color;
    }
  }
}

button,
a {
  &.primary {
    color: white;
    background-color: #48f;
    font-size: 18px;
    border-radius: 40px;
    font-weight: 600;
    border: 0;
    padding: 14px;
    margin: 0 0 24px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    cursor: pointer;

    i {
      margin-right: 4px;
    }

    &:hover {
      background-color: darken(#48f, 5);
    }

    &:disabled {
      background-color: #ccc;
    }
  }
}
