.outer {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 901;
  overflow: auto;
  display: flex;
}

.background {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 900;
  background-color: rgba(0, 0, 0, 0.2);
}

$content-margin: 64px;
$content-radius: 32px;
$close-size: 32px;

.container {
  margin: auto 0 auto auto;
  z-index: 902;
  padding: $content-margin;
  display: flex;
  flex-direction: row-reverse;
}

.outerButtons {
  margin-right: -$content-radius / 4;
  margin-top: -$close-size + $content-radius / 4;

  i {
    color: white;
    font-weight: 900;
    font-size: $close-size;
    text-shadow: 0 0 16px rgba(0, 0, 0, 0.2);
    // border-radius: 18px;
    // background-color: rgba(0, 0, 0, 0.5);
    cursor: pointer;
  }
}

.contentWrapper {
  overflow: hidden;

  background-color: white;
  border-radius: $content-radius;
  border: 4px solid white;
  box-shadow: 0 0 32px -8px rgba(0, 0, 0, 0.15);
}
