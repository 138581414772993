.wrapper {
  height: 100%;

  display: flex;
  flex-direction: row;

  > *:not(:last-child) {
    margin-right: 8px;
  }
}

.profileBox {
  display: flex;
  align-items: center;
}

.actionLink {
  display: flex;
  align-items: center;
  color: #888;
  text-decoration: none;
  padding: 0 8px;
}
