@import '../../global.module.scss';

a {
  color: #2b78ff;
}

section {
  margin: 32px 0 64px 0;
}

.userSection {
  padding: 16px 32px;

  background: #f8f9fa;
  border-radius: 32px;
  box-shadow: 0 16px 32px -16px rgba(128, 128, 128, 0.2);

  .welcome {
    font-weight: bold;
  }
}

.hello {
  max-width: 800px;
  margin: 0 auto;

  img {
    &.screenshot {
      max-width: calc(100% - 16px);
      margin: 0 8px;
      border: 1px solid #ddd;
      border-radius: 4px;
      box-shadow: 0 8px 32px rgba(#444, 0.1);
    }
  }
}

@media screen and (max-width: 816px) {
  .hello {
    margin: 0 8px;
  }
}
