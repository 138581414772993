.timetableItem {
  padding: 4px;
  border: 1px solid #ccc;
  box-sizing: border-box;
  background-color: #f8f8f8;
  overflow: hidden;
  word-break: break-all;
  height: 100%;

  .overlayMenu {
    display: none;
    position: absolute;
    top: 2px;
    right: 4px;

    list-style: none;
    margin: 0;
    padding: 2px;
    color: #444;
    font-weight: 900;
    text-shadow: 0 0 2px white;

    display: none;
    flex-direction: row;

    > li {
      margin: 0;

      button {
        cursor: pointer;

        display: flex;
        align-items: center;
        justify-content: center;

        font-size: 20px;
        font-weight: 900;
        padding: 4px;
        color: #444;
        background-color: white;
        border-radius: 100%;
        box-shadow: 0 0 12px -6px rgba(0, 0, 0, 0.2);

        &:hover {
          background-color: darken(white, 10);
        }

        &.editing {
          opacity: 0.33;
        }
      }
    }
  }

  &:hover {
    .overlayMenu {
      display: flex;
    }
  }

  .title {
    margin: 4px 0;
    font-weight: bold;
  }
  .meta {
    margin: 2px 0;
    color: #444;
  }

  &.preview {
    background: repeating-linear-gradient(
      -45deg,
      rgba(#ffff96, 0.66),
      rgba(#ffff96, 0.66) 10px,
      rgba(#f2f28c, 0.66) 0,
      rgba(#f2f28c, 0.66) 20px
    );
    z-index: 2 !important;
  }

  &.overlapped {
    background: repeating-linear-gradient(
      -45deg,
      rgba(#ff9696, 0.66),
      rgba(#ff9696, 0.66) 10px,
      rgba(#f22a30, 0.66) 0,
      rgba(#f22a30, 0.66) 20px
    );
    color: white;
    border: 1px solid #ff5f66;

    .title,
    .meta {
      color: white;
    }
  }
}
