@import '../../global.module.scss';

.container {
  max-width: 600px;
  margin: 32px auto 128px auto;
}

.muted {
  color: #aaa;
}

.full {
  width: 100%;
}
