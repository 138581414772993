.wrapper {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;

  .masterContainer {
    margin: 0 auto;
  }

  .detailContainer {
  }

  @media screen and (min-width: 961px) {
    // padding: 0 16px;
    // box-sizing: border-box;

    .masterContainer,
    .detailContainer {
      height: 100%;
    }

    .masterContainer {
      margin: 0 auto;
      flex: 4;
      max-width: 650px;
      // min-width: 320px;
    }

    .detailContainer {
      flex: 5;
    }
  }

  @media screen and (max-width: 960px) {
    flex-direction: column;

    .masterContainer,
    .detailContainer {
      // background-color: yellow;
      width: 100%;
    }
  }
}
