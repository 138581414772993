.menuButton {
  cursor: pointer;
  padding: 0 4px;
  margin-left: 8px;
  background: none;
  border: none;
  height: 100%;
  > i {
    font-size: 20px;
    vertical-align: middle;
  }
}
