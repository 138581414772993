.container {
  h1 {
    font-size: 2em;
    font-weight: 900;
  }

  dl.details {
    color: black;

    .detailItem {
      display: flex;

      &:not(:first-child) {
        margin-top: 8px;
      }
    }

    $content-line-height: 180%;

    font-size: 20px;

    dt {
      min-width: 120px;
      flex: 1;
      text-align: right;
      padding-right: 16px;
      box-sizing: border-box;
      color: #777;
      line-height: $content-line-height;
    }

    dd {
      flex: 3;
      font-weight: 500;
      margin: 0;
      line-height: $content-line-height;
    }
  }

  ul.times,
  ul.tags {
    margin: 0;
    display: flex;
    flex-direction: column;

    > li {
      margin: 0;

      &:before {
        margin-right: 4px;
      }
    }
  }

  .times > li:before {
    content: '🗓';
  }

  .tags > li:before {
    content: '🔖';
  }

  $button-color: #48f;

  .footerButton {
    width: 100%;
    padding: 16px;
    border: none;
    border-radius: 16px;
    background-color: $button-color;
    color: white;
    font-size: 18px;
    font-weight: 600;
    cursor: pointer;

    &:hover {
      background-color: darken($button-color, 10);
    }

    &:disabled {
      background-color: #eee;
      cursor: not-allowed;
    }
  }
}
