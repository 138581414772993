@import '../../../../global.module.scss';

.container {
  max-width: 600px;
  margin: 32px auto 128px auto;

  .sectionsContainer,
  .timetablesContainer {
    list-style: none;
    padding: 0;
  }

  .sectionsContainer {
    > li {
      margin-bottom: 32px;

      .semesterHeader {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        .semesterTitle {
          font-weight: 700;
          color: #444;
        }

        .semesterAddButton {
          display: flex;
          align-items: center;
          padding: 0 20px;
          color: #888;
        }
      }
    }
  }

  .timetablesContainer {
    > li {
      &:not(:last-child) {
        margin-bottom: 8px;
      }

      .timetableContent {
        display: block;
        text-decoration: none;
        background-color: #f4f4f4;
        color: #444;
        padding: 16px 32px;
        font-weight: 600;
        border-radius: 2em;

        &:hover {
          background-color: darken(#f4f4f4, 2);
        }

        &:last-child::after {
          content: '〉';
          float: right;
          color: #bbb;
          font-weight: 900;
          margin-right: -4px;
        }
      }
    }
  }

  .btnNew {
    width: 100%;
  }

  .emptyDataSet {
    text-align: center;
    max-width: 600px;
    margin: 0 auto;
    padding: 0 16px;

    .emoji {
      font-size: 64px;
      margin-bottom: 0;
    }
    .message {
      font-size: 24px;
      line-height: 160%;
    }
  }

  a {
    text-decoration: none;
  }
}
