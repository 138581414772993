// $navbar-height: 46px;

.navbar {
  // min-height: $navbar-height;
  height: 100%;
  padding: 0 14px;
  border-bottom: 1px solid #ddd;
  background-color: #f8f8f8;
  width: 100%;
  box-sizing: border-box;
  z-index: 100;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.leftSection {
  display: flex;
  flex-direction: row;

  .logo {
    display: flex;
    align-items: center;
    padding: 0 8px;

    color: #888;
    text-decoration: none;
  }
}

.rightSection {
  height: 100%;
}
