.container {
  display: flex;
  flex-direction: column;

  .contentWrapper {
  }

  .menuWrapper {
    position: relative;

    .menu {
      position: absolute;
      top: 0;
      left: 0;
    }
  }
}
