.container {
  height: 100vh;
  display: flex;
  flex-direction: column;

  .header {
    height: 46px;
    z-index: 1;
  }

  .content {
    flex: 1;
    overflow: auto;
  }
}
